function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/**
 * @class Oskari
 *
 * A set of methods to support loosely coupled classes and instances for the mapframework
 */
import Sequence from './counter.es6';
import Logger from './logger.es6';
import pkg from '../package.json';
import { DOMHelper } from './oskari.dom';
import { Customization } from './oskari.customization';
import { BundleRegister } from './BundleRegister';
var defaultSequence = new Sequence();
var sequences = {};
// keep track of existing loggers
var loggers = {};
var _urls = {};
function getUrl(key) {
  return _urls[key];
}
function encodeParams(params) {
  if (_typeof(params) !== 'object') {
    return '';
  }
  return Object.keys(params).filter(function (k) {
    return params[k] != null && typeof params[k] !== 'undefined';
  }).map(function (k) {
    return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
  }).join('&');
}
function appendQueryToURL(url, query) {
  if (typeof query === 'undefined' || query === '') {
    return url;
  }
  if (url.indexOf('?') === -1) {
    return "".concat(url, "?").concat(query);
  }
  if (url.endsWith('?')) {
    return url + query;
  }
  if (url.endsWith('&')) {
    return url + query;
  }
  return "".concat(url, "&").concat(query);
}
var Oskari = {
  VERSION: pkg.version,
  setMarkers: function setMarkers(markers) {
    Oskari.log('Oskari').deprecated('setMarkers', 'Use Oskari.custom.setMarkers() instead');
    Customization.setMarkers(markers);
  },
  getMarkers: function getMarkers() {
    Oskari.log('Oskari').deprecated('getMarkers', 'Use Oskari.custom.getMarkers() instead');
    return Customization.getMarkers();
  },
  getDefaultMarker: function getDefaultMarker() {
    Oskari.log('Oskari').deprecated('getDefaultMarker', 'Use Oskari.custom.getMarker() instead');
    return Customization.getMarker();
  },
  // from BundleRegister.js
  bundle: BundleRegister.bundle,
  lazyBundle: BundleRegister.lazyBundle,
  // from oskari.customization.js
  custom: Customization,
  // from oskari.dom
  dom: DOMHelper,
  seq: defaultSequence,
  getSeq: function getSeq(type) {
    if (typeof type === 'undefined') {
      return defaultSequence;
    } else if (!sequences[type]) {
      sequences[type] = new Sequence();
    }
    return sequences[type];
  },
  log: function log() {
    var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Oskari';
    if (loggers[name]) {
      return loggers[name];
    }
    var log = new Logger(name);
    loggers[name] = log;
    return log;
  },
  urls: {
    /**
     * Oskari.urls.set({
             "map" : "https://my.map.com",
            "api": "https://api.map.com/action?",
            "login" :"https://my.map.com/login",
            "register" :"http://some.auth.site.com/register",
            "tou" :"http://my.organization/map/tou",
        });
        OR
        Oskari.urls.set('login', 'https://my.map.com/login');
        */
    set: function set(urlsOrKey, optionalValue) {
      if (typeof urlsOrKey === 'string') {
        _urls[urlsOrKey] = optionalValue;
      } else if (_typeof(urlsOrKey) === 'object') {
        _urls = urlsOrKey || {};
      } else {
        throw new Error('Unrecognized parameter for urls: ' + urlsOrKey);
      }
    },
    /**
     * Generic url "location" getter
     * @param  {String} key type of url like "login" or "registration"
     * @return {String} URL that points to requested functionality
     */
    getLocation: function getLocation(key) {
      return getUrl(key);
    },
    /**
     * Action route urls
     * @param  {String} route optional route name. Returns base url if name is not given.
     * @param  {Object} optionalParams optional object that will be encoded as querystring parameters for the URL.
     * @return {String} url to use when making API calls
     */
    getRoute: function getRoute(route, optionalParams) {
      var url = appendQueryToURL(getUrl('api') || '/action?', encodeParams(optionalParams));
      if (route) {
        return appendQueryToURL(url, 'action_route=' + route);
      }
      return url;
    },
    /**
     * Builds an URL by attaching optional parameters to base url
     * @param {String} url complete baseUrl that might already have querystring
     * @param {*} optionalParams parameters that should be attached to baseUrl
     * @returns base url with optional params included as querystring
     */
    buildUrl: function buildUrl(url, optionalParams) {
      return appendQueryToURL(url, encodeParams(optionalParams));
    }
  }
};
window.Oskari = Oskari; // TODO: remove when whole of core is ES6

export default Oskari;