var _excluded = ["data"];
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var PLACEHOLDER_FILL = '$fill';
var PLACEHOLDER_STROKE = '$stroke';
var SVG_SRC = 'data:image/svg+xml;utf8,';
var EMPTY_MARKER = {
  data: '<svg/>'
};
var STROKE_COLOR = '#000000';
var FILL_COLOR = '#000000';
var SVG_SIZE = 32;
var DEFAULT_MARKER = 2;
var COLORS = ['#ffffff', '#cccccc', '#818282', '#666666', '#000000', '#f8931f', '#ffde00', '#ff3334', '#bf2652', '#652d90', '#3233ff', '#2ba7ff', '#26bf4b', '#00ff01', '#ffff00'];
var _markers = [];
var _iconSrc = null;
var _defaultStyle = {};
var _customColors = null;
var _themeColors = {};

// getPixelForSize / markerTemplate svg viewBox size
var getScale = function getScale() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (40 + 10 * size) / 64;
};
var setColors = function setColors(colors) {
  _customColors = colors;
};
var getColors = function getColors() {
  return Array.isArray(_customColors) ? _toConsumableArray(_customColors) : [].concat(COLORS);
};

// AppSetup.env.svgMarkers
var setMarkers = function setMarkers(markers) {
  _markers = markers || [];
  _iconSrc = null;
};
var getMarkers = function getMarkers() {
  return _markers;
};
var getMarker = function getMarker(shape) {
  var marker = _markers[shape];
  if (marker) {
    return _objectSpread({}, marker);
  }
  if (typeof shape === 'number') {
    Oskari.log('Oskari customization').warn("Requested marker: ".concat(shape, " doesn't exist. Returning default marker instead."));
  }
  var defaultMarker = _markers[DEFAULT_MARKER] || _markers[0] || EMPTY_MARKER;
  return _objectSpread({}, defaultMarker);
};

// All svg icons with default colors for style editor buttons etc..
var getSvgIcons = function getSvgIcons() {
  if (!_iconSrc) {
    _iconSrc = getMarkers().map(function (m, index) {
      return getSvg({
        shape: index,
        fill: {
          color: FILL_COLOR
        }
      });
    });
  }
  return _iconSrc;
};

// oskari style image object
var getSvg = function getSvg(_ref) {
  var shape = _ref.shape,
    size = _ref.size,
    fill = _ref.fill,
    stroke = _ref.stroke;
  var _getMarker = getMarker(shape),
    data = _getMarker.data,
    offsets = _objectWithoutProperties(_getMarker, _excluded);
  var svg = data.replace(PLACEHOLDER_FILL, (fill === null || fill === void 0 ? void 0 : fill.color) || FILL_COLOR).replace(PLACEHOLDER_STROKE, (stroke === null || stroke === void 0 ? void 0 : stroke.color) || STROKE_COLOR);
  return _objectSpread(_objectSpread({}, offsets), {}, {
    scale: getScale(size),
    src: SVG_SRC + encodeURIComponent(svg)
  });
};
var getDefaultStyle = function getDefaultStyle() {
  return _objectSpread({}, _defaultStyle);
};

// AppSetup.env.oskariStyle
var setDefaultStyle = function setDefaultStyle(style) {
  _defaultStyle = style || {};
};
var getFillColor = function getFillColor() {
  return _themeColors.primary || '#FAEBD7';
};

// to init style editor with theme color
var generateBlankStyle = function generateBlankStyle() {
  var color = getFillColor();
  if (!color) {
    return _objectSpread({}, _defaultStyle);
  }
  return _objectSpread(_objectSpread({}, _defaultStyle), {}, {
    fill: _objectSpread(_objectSpread({}, _defaultStyle.fill), {}, {
      color: color
    }),
    image: _objectSpread(_objectSpread({}, _defaultStyle.image), {}, {
      fill: {
        color: color
      }
    })
  });
};

// AppSetup.env.app.theme
var setThemeColors = function setThemeColors() {
  var theme = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!theme.color) {
    return;
  }
  _themeColors = _objectSpread({}, theme.color);
  // Add theme colors to predefined colors
  var colorsFromTheme = Object.values(_themeColors).reduce(function (colors, cur) {
    if (Array.isArray(cur)) {
      return [].concat(_toConsumableArray(colors), _toConsumableArray(cur));
    }
    if (_typeof(cur) === 'object') {
      return [].concat(_toConsumableArray(colors), _toConsumableArray(Object.values(cur)));
    }
    return [].concat(_toConsumableArray(colors), [cur]);
  }, []).filter(function (color) {
    return Oskari.util.colorToArray(color).length;
  });
  setColors(_toConsumableArray(new Set([].concat(COLORS, _toConsumableArray(colorsFromTheme)))));
};
export var Customization = {
  setMarkers: setMarkers,
  getMarkers: getMarkers,
  getMarker: getMarker,
  getSvg: getSvg,
  getSvgIcons: getSvgIcons,
  SVG_SIZE: SVG_SIZE,
  setColors: setColors,
  getColors: getColors,
  getDefaultStyle: getDefaultStyle,
  setDefaultStyle: setDefaultStyle,
  getFillColor: getFillColor,
  generateBlankStyle: generateBlankStyle,
  setThemeColors: setThemeColors
};