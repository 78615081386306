function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/**
 * Startupsequence processor for Oskari. Bundles have ether been bundled/minfied
 * into the application JS file or separate chunks. In both cases bundles must be
 * declared in miniferAppSetup.json
 *
 * Usage:
 *
 *     var startupSequence = [...bundles to load/start... ];
 *     var config = { ... bundle configs ... };
 *     var loader = Oskari.loader(startupSequence, config);
 *     loader.log.enableDebug(true); // to get additional logging
 *     loader.processSequence(function() {
 *         // application started
 *     });
 *
 */
(function (o) {
  if (!o) {
    // can't add loader if no Oskari ref
    return;
  }
  if (o.loader) {
    // loader already present, but we might want another?
  }
  var log = Oskari.log('Loader');
  var linkFile = function linkFile(href, rel, type) {
    var importParentElement = document.head || document.body;
    var linkElement = document.createElement('link');
    linkElement.rel = rel || 'stylesheet';
    linkElement.type = type || 'text/css';
    linkElement.href = href;
    importParentElement.appendChild(linkElement);
  };

  /**
   * @method loadDynamic
   * Called to start dynamic loading of a bundle
   *
   * @param {string} bundlename Bundle name
   *
   * @return Promise that resolves when all modules have loaded
   */
  var loadDynamic = function loadDynamic(bundlename) {
    var loaders = Oskari.lazyBundle(bundlename);
    if (loaders) {
      return Promise.all(loaders.map(function (l) {
        return l.call();
      }));
    }
    return null;
  };

  /**
   * Loader
   * @param  {Object[]} startupSequence sequence of bundles to load/start
   *         {
   *              "bundleinstancename": "openlayers-default-theme",
   *              "bundlename": "openlayers-default-theme"
   *         }
   * @param  {Object}   config          configuration for bundles
   */
  var loader = function loader(startupSequence, config) {
    var sequence = [];
    if (startupSequence && typeof startupSequence.slice === 'function') {
      sequence = startupSequence.slice(0);
    } else {
      log.warn('No startupsequence given to loader or sequence is not an array');
    }
    var appConfig = config || {};

    // Listen to started bundles
    var result = {
      bundles: [],
      errors: []
    };
    o.on('bundle.start', function (details) {
      result.bundles.push(details.id);
    });
    o.on('bundle.err', function (details) {
      result.errors.push(details);
    });
    return {
      /**
       * @param  {Function} done callback
       */
      processSequence: function processSequence(done) {
        var _this = this;
        var suppressStartEvent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var me = this;
        if (sequence.length === 0) {
          // everything has been loaded
          if (typeof done === 'function') {
            done(result);
          }
          if (!suppressStartEvent) {
            o.trigger('app.start', result);
          }
          return;
        }
        var seqToLoad = sequence.shift();
        if (_typeof(seqToLoad) !== 'object') {
          // log warning: block not object
          log.warn('StartupSequence item is a ' + _typeof(seqToLoad) + ' instead of object. Skipping');
          // iterate to next
          this.processSequence(done, suppressStartEvent);
          return;
        }
        var bundleToStart = seqToLoad.bundlename;
        if (!bundleToStart) {
          log.warn('StartupSequence item doesn\'t contain bundlename. Skipping ', seqToLoad);
          // iterate to next
          this.processSequence(done, suppressStartEvent);
          return;
        }
        // if bundleinstancename is missing, use bundlename for config key.
        var configId = seqToLoad.bundleinstancename || bundleToStart;
        var config = appConfig[configId] || {};
        if (Oskari.bundle(bundleToStart)) {
          log.debug('Bundle preloaded ' + bundleToStart);
          me.startBundle(bundleToStart, config, configId);
          this.processSequence(done, suppressStartEvent);
          return;
        }
        var bundlePromise = loadDynamic(bundleToStart);
        if (!bundlePromise) {
          log.warn('Bundle wasn\'t preloaded nor registered as dynamic. Skipping ', bundleToStart);
          this.processSequence(done, suppressStartEvent);
          return;
        }
        bundlePromise.then(function () {
          me.startBundle(bundleToStart, config, configId);
          _this.processSequence(done, suppressStartEvent);
        }).catch(function (err) {
          log.error('Error loading bundle ' + bundleToStart, err);
          me.processSequence(done, suppressStartEvent);
        });
      },
      startBundle: function startBundle(bundleId, config, instanceId) {
        var bundle = Oskari.bundle(bundleId);
        if (!bundle) {
          throw new Error('Bundle not loaded ' + bundleId);
        }
        var instance;
        if (typeof bundle === 'function') {
          instance = bundle();
        } else {
          // assume old bundle def
          instance = bundle.clazz.create();
        }
        if (!instance) {
          throw new Error('Couldn\'t create an instance of bundle ' + bundleId);
        }
        instance.mediator = {
          bundleId: bundleId,
          instanceId: instanceId
        };
        // quick'n'dirty property injection
        for (var key in config) {
          instance[key] = config[key];
        }
        log.debug('Starting bundle ' + bundleId);
        try {
          instance.start(Oskari.getSandbox());
          Oskari.trigger('bundle.start', {
            id: bundleId
          });
        } catch (err) {
          Oskari.trigger('bundle.err', {
            id: bundleId,
            error: err
          });
          log.error('Couldn\'t start bundle with id ' + bundleId + '. Error was: ', err);
          throw err;
        }
      }
    };
  };

  // setup additional members
  loader.log = log;
  // accessible without calling loader() with loader.linkFile()
  loader.linkFile = linkFile;
  // attach to Oskari
  o.loader = loader;
})(Oskari);